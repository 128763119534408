<template>
  <div class="crumb-rq">
    <el-breadcrumb :separator-icon="ArrowRight">
      <el-breadcrumb-item :to="{ path: '/helpCenter' }">帮助中心</el-breadcrumb-item>
      <el-breadcrumb-item>搜索结果</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  <div class="my-help-list">
    <ul class="my-help-for-data-rq">
      <li class="my-help-for-data" v-for="item in listData" :key="item">
        <a :href="'/helpCenterSubpage/id/'+item.id">
          {{ item.title }}
        </a>
      </li>
    </ul>
  </div>
  <div class="pagingBox" v-show="total>0">
    <!--分页-->
    <el-pagination @change="searchHelpList" :page-size="pageSize" background :total="total"
                   v-model:current-page="pageNumber" layout="prev, pager, next "></el-pagination>
  </div>
  <el-empty v-show="total===0" description="暂无数据" />
  <Footers></Footers>
</template>
<script setup lang="ts">
import Footers from "@/src/components/wap/footer/index.vue";
import { ArrowRight } from '@element-plus/icons-vue'
import { getSearchHelpList } from "@/src/api/apiconfig/main";

const route = useRoute()
//分页总条数
const total = ref()
const pageNumber = ref(1)
const pageSize = ref()
const listData = ref()
const inputData = ref()
//搜索键盘按压事件
const handleEnter = ((data: any) => {
  searchHelpList(pageNumber.value)
})
const searchHelpList = (async (data: any) => {
  const response = await getSearchHelpList({page: data, search: inputData.value ? inputData.value : route.params.value})
  if (response.data.list) {
    total.value = response.data.total
    listData.value = response.data.list
    pageSize.value = response.data.pagesize
  }
})
await searchHelpList(pageNumber.value)
useHead({
  title:'松鼠快图_帮助中心详细内容列表',
  meta:[
    {hid:'keywords',name:'keywords','content':'松鼠快图,帮助中心,文章列表'},
    {hid:'description',name:'description','content':'松鼠快图帮助中心详细内容列表，提供使用过程中的帮助内容。'},
  ]
})
</script>
<style>
.crumb-rq{
  padding: 0 10px;
  margin: 20px 10px;
}
.my-help-for-data{
  list-style-type: disc;
  margin-bottom: 20px;
}
.pagingBox{
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
  margin-top: 40px;
}
.my-help-list{
  margin-top: 30px;
}
</style>