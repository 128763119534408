<!--模板中心-->
<template>
  <component :is="deviceComponent" />
</template>
<script setup lang="ts">
import helpCenterSearchPc  from "@/src/components/helpCenter/helpCenterSearchPc.vue";
import helpCenterSearchWap from "@/src/components/wap/helpCenter/search.vue";
import { useDevice } from '@/src/utils/useDevice'
const { deviceComponent } = useDevice(helpCenterSearchPc, helpCenterSearchWap)
</script>
<style scoped>
body{

}
</style>
