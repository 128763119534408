<template>
  <div class="helpBox">
    <div class="headBox">
      <div class="image">
        <NuxtLink :to="'/'">
          <img src="/public/img/logo_h.png" alt=""/>
        </NuxtLink>
      </div>
      <div class="line"></div>
      <div class="helpCenterTitle" @click="router.push('/helpCenter')"><h3>帮助中心</h3></div>
    </div>
    <div class="listBox">
      <div class="inputCla">
        <el-input @keydown.enter="handleEnter(inputData)" style="width: 600px;height: 40px" placeholder="请输入搜索内容"
                  v-model="inputData">
          <template #suffix>
            <el-icon style="cursor: pointer" class="el-input__icon">
              <component :is="Search" @click="handleEnter(inputData)"/>
            </el-icon>
          </template>
        </el-input>
      </div>
      <div class="breadcrumbBox">
        <FullLoading v-if="fullscreenLoading" />
        <div class="breadcrumb">
          <el-breadcrumb :separator-icon="ArrowRight">
            <el-breadcrumb-item :to="{ path: '/helpCenter' }">帮助中心</el-breadcrumb-item>
            <el-breadcrumb-item>搜索结果</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="textBox" v-for="item in listData">
          <div>
            <a target="_blank" :href="'/helpCenterSubpage/id/'+item.id">{{ item.title }}</a>
            <div>{{ item.content }}</div>
          </div>
        </div>
        <div class="pagingBox" v-show="total>0">
          <!--            分页-->
          <el-pagination @change="searchHelpList" :page-size="pageSize" background :total="total"
                         v-model:current-page="pageNumber" layout="prev, pager, next "></el-pagination>
          <div>
            共有 <b>{{ total }}</b> 条数据
          </div>
        </div>
        <el-empty v-show="total===0" description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ArrowRight} from '@element-plus/icons-vue'
import {Search} from '@element-plus/icons-vue'
import {getSearchHelpList} from "../../api/apiconfig/main";

useHead({
  title:'松鼠快图_帮助中心详细内容列表',
  meta:[
    {hid:'keywords',name:'keywords','content':'松鼠快图,帮助中心,文章列表'},
    {hid:'description',name:'description','content':'松鼠快图帮助中心详细内容列表，提供使用过程中的帮助内容。'},
  ]
})


const fullscreenLoading = ref(true)
const router = useRouter()
const route = useRoute()
//分页总条数
const total = ref()
const pageNumber = ref(1)
const pageSize = ref()
const listData = ref()
const inputData = ref()
//搜索键盘按压事件
const handleEnter = ((data: any) => {
  searchHelpList(pageNumber.value)
})
const searchHelpList = (async (data: any) => {
  const response = await getSearchHelpList({page: data, search: inputData.value ? inputData.value : route.params.value})
  if (response.data.list) {
    total.value = response.data.total
    listData.value = response.data.list
    pageSize.value = response.data.pagesize
  }
  fullscreenLoading.value = false
})
await searchHelpList(pageNumber.value)
</script>

<style lang="less" scoped>
.helpBox {
  .headBox {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 1000px;
    height: 56px;
    padding: 0 28px;
    margin: 0 auto;
    box-shadow: 0 1px #f1f2f4;
    user-select: none;

    .image {
      img {
        width: 200px;
        height: 56px;
      }
    }

    .line {
      width: 1px;
      height: 20px;
      margin-right: 15px;
      margin-left: 15px;
      background: #b4b8bf;
    }

    .helpCenterTitle {
      cursor: pointer;
    }
  }

  .listBox {
    width: 100%;
    max-width: 872px;
    padding-bottom: 40px;
    margin: 0 auto;
    min-height: 100vh;
    user-select: none;

    .inputCla {
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .breadcrumbBox::-webkit-scrollbar {
      display: none; /* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
    }

    .breadcrumbBox {
      height: calc(100vh - 80px);
      overflow: auto;
      margin-bottom: 50px;

      .breadcrumb {
        margin-top: 20px;
        margin-left: 5px;
      }

      .textBox {
        margin-top: 30px;

        a {
          font-size: 18px;
          font-weight: 600;
          color: #222529;
          text-decoration: none;
        }

        div {
          line-height: 2;
          font-size: 16px;
          white-space: pre-wrap;
          color: #7f8792;
          margin-top: 5px;
        }
      }

      .pagingBox {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        div {
          margin-left: 10px;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

  }
}
</style>